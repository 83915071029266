<template>
  <div class="hub-component">
    <br><br>
    <br><br><br><br>
    <h1 class="text-center">Submissions</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="'image-' + index"
          cols="12" sm="4"
        >
          <v-sheet class="ma-2 pa-4" style="width: 256px; height: 256px;">
            <v-img :src="image.url ? image.url : 'https://via.placeholder.com/128x128'" aspect-ratio="1" contain></v-img>
            <div>{{ image.name }}</div>
          </v-sheet>
        </v-col>
        <v-col
          v-for="(audio, index) in audios"
          :key="'audio-' + index"
          cols="12"
        >
          <audio controls>
            <source :src="audio.url" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
          <div>{{ audio.name }}</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <hr>
  <div>
    <v-file-input
      :rules="rules"
      accept="image/png, audio/mpeg"
      placeholder="Pick a file"
      prepend-icon="mdi-upload"
      label="Click To Upload..."
      @change="uploadFile"
      multiple
    ></v-file-input>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed

    const fetchSubmissions = async () => {
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('workshop')
          .list('', { limit: 1000 })

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls

        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('workshop')
            .download(file)

          const fileUrl = URL.createObjectURL(url)

          if (urlError) {
            throw urlError
          }

          // Check if the file is an image or an audio file
          if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg')) {
            images.value.push({ url: fileUrl, name: file })
          } else if (file.endsWith('.mp3')) {
            audios.value.push({ url: fileUrl, name: file })
          }
        }
      } catch (fetchError) {
        console.error('Error retrieving submissions:', fetchError)
        error.value = fetchError.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const filePath = file.name  // use the original file name

          const { error: uploadError } = await supabase.storage
            .from('workshop')
            .upload(filePath, file)

          if (uploadError) throw uploadError
        }
        
        console.log('File uploaded successfully!')
        fetchSubmissions()  // Refresh the submissions list after successful upload
      } catch (uploadError) {
        console.error('Error uploading file:', uploadError.message)
      }
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      rules,
      uploadFile
    }
  }
}
</script>
