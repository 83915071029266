<template>
  <div class="hub-component">
    <br /><br />
    <br /><br /><br /><br />
    <h1 class="text-center">Submissions</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="'image-' + index"
          cols="12"
          sm="4"
        >
          <v-sheet class="ma-2 pa-4" style="width: 256px; height: 256px;">
            <v-img
              :src="image.url ? image.url : 'https://via.placeholder.com/128x128'"
              aspect-ratio="1"
              contain
            ></v-img>
            <!--<div>{{ image.name }}</div>-->
          </v-sheet>
        </v-col>
        <v-col v-for="(audio, index) in audios" :key="'audio-' + index" cols="12">
          <audio controls>
            <source :src="audio.url" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <hr />

  <div>
    <v-text-field v-model="username" label="Enter your name" required></v-text-field>
    <v-file-input
      :rules="rules"
      :accept="allowedFileType"
      placeholder="Pick a file"
      prepend-icon="mdi-upload"
      label="Click To Upload..."
      @change="uploadFile"
      multiple
    ></v-file-input>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.mp3') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week5_library_workshop') // Use 'week5_library_workshops' bucket
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week5_library_workshop') // Use 'week5_library_workshops' bucket
            .download(file)

          console.log("Type of URL:", typeof url, url)

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url); // Assign the URL to fileUrl
            // Check if the file is an image or an audio file
            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg')) {
              //images.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              audios.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }


          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week5_library_workshop') // Use 'week5_library_workshops' bucket
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      rules,
      username,
      uploadFile,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
