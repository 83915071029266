<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>
    <br>
    <iframe frameborder="0" src="https://itch.io/embed/2429817" width="552" height="167"><a href="https://the-porg.itch.io/top-down">Top down by The_Porg</a></iframe><br>
    <a href="https://gd.games/instant-builds/12abc471-e023-488d-a427-eaad32ef70ba">Thomas</a><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2429828" width="552"><a href="https://sleepyhalo.itch.io/space">space by sleepyhalo</a></iframe><br>
    <iframe frameborder="0" src="https://itch.io/embed/2429824" width="552" height="167"><a href="https://phtevenincredible.itch.io/hoongs-chair">Hoong&#039;s chair by PhtevenIncredible</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2429818" width="552"><a href="https://berzerkerman.itch.io/apocalypse">apocalypse by BERZERKER.MAN</a></iframe><br>
    <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2429814"><a href="https://infernodev.itch.io/sink-the-brits">Sink the Brits by Infernodev</a></iframe><br>
    <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2429809"><a href="https://astral-atlas.itch.io/paper-bag-war">Paper bag war by Atlas xD</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2431329" width="552"><a href="https://confus1onnn.itch.io/space-dude">Space Dude by Confus1onnn</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2429822" width="552"><a href="https://maxpayton.itch.io/apex-anni">Apex Annihilation by Maxpayton</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2431325" width="552"><a href="https://better-snail.itch.io/snail-shooter2">Snail Shooter2 by Better_Snail</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2431328" width="552"><a href="https://dtacat.itch.io/1">1 by DTACat</a></iframe><br>
    <iframe height="167" frameborder="0" src="https://itch.io/embed/2431324" width="552"><a href="https://smokywhale.itch.io/dino-dash">Dino Dash by SmokyWhale</a></iframe><br>
    <iframe frameborder="0" src="https://itch.io/embed/2431330" width="552" height="167"><a href="https://im-not-here450.itch.io/bunny-pew-pew">Bunny Pew - Pew by im_not_here450</a></iframe><br>
    <iframe frameborder="0" src="https://itch.io/embed/2431327" width="552" height="167"><a href="https://bobslayer2000.itch.io/topdown">Topdown by Bobslayer2000</a></iframe><br>
    <a href="https://gd.games/instant-builds/c9193fc6-ba51-4ac4-86d8-55457b8061e5">Marcus</a><br>
    <a href="https://gd.games/stoomped/odacava-and-the-fiesty-fiends-">Beck</a>
</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'
//update
export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.png') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
            .download(file)

          console.log("Type of URL:", typeof url, url)

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url); // Assign the URL to fileUrl
            // Check if the file is an image or an audio file
            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.gif') || file.endsWith('.obj')) {
              images.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              //audios.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }


          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      rules,
      username,
      uploadFile,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
