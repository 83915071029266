<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-4" outlined>
          <v-card-text>
            <h1 class="text-center mb-4">Login!</h1>
            <p>Please login with the credentials you have been provided with.</p>
            <v-text-field
              v-model="email"
              :counter="50"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              :counter="50"
              :rules="passwordRules"
              label="Password"
              type="password"
              required
            ></v-text-field>
            <v-btn color="primary" @click="login">Login</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </template>
  
  <script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Login',
  setup() {
    const email = ref('')
    const password = ref('')
    const emailRules = [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ]
    const passwordRules = [v => !!v || 'Password is required']

    const router = useRouter()

    const login = async () => {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email.value,
          password: password.value,
        });

        if (error) throw error
        alert("Welcome!")
        console.log('User:', data)

        if (data) {
          // Fetch profile data after successful login
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('username')
            .eq('id', data.user.id)
            .single()

          if (profileError) throw profileError
          
          if (profile && profile.username) {
            // Navigate to the user's profile page
            router.push({ name: 'Profile', params: { username: profile.username } })
          }
        }
      } catch (error) {
        console.error('Error: ', error.message)
      }
    }

    return {
      email,
      password,
      emailRules,
      passwordRules,
      login
    }
  },
}
  </script>
  
  <style scoped>
  h1 {
    font-family: 'League Spartan', sans-serif;
  }
  </style>
  