<template>
  <div>
    <v-file-input
      :rules="rules"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Pick an avatar"
      prepend-icon="mdi-dropbox"
      label="Click To Upload..."
      @change="uploadImage"
    ></v-file-input>
  </div>
</template>

<script>
import { ref } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'EditProfile',
  setup() {
    const rules = ref([/* Add your validation rules here */])

    const uploadImage = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      try {
        const { data: { user } } = await supabase.auth.getUser()
        const file = files[0]
        const fileExt = file.name.split('.').pop()
        const filePath = `${Math.random()}.${fileExt}`

        const { error: uploadError } = await supabase.storage
          .from('images') // Replace 'images' with your bucket name
          .upload(filePath, file)

        if (uploadError) throw uploadError

        // Update the images array in the profiles table

        if (!user) throw new Error('User not found')

        const { data, error: profileError } = await supabase
          .from('profiles')
          .select('images')
          .eq('id', user.id)
          .single()

        if (profileError) throw profileError

        const images = data.images || []
        images.push(filePath)

        const { error: updateError } = await supabase
          .from('profiles')
          .update({ images })
          .eq('id', user.id)

        if (updateError) throw updateError

        console.log('Image uploaded and profile updated successfully!')
        location.reload();
      } catch (error) {
        console.error('Error uploading image and updating profile:', error.message)
      }
    }

    return {
      rules,
      uploadImage
    }
  }
}
</script>

<style scoped>
h1 {
  font-family: 'League Spartan', sans-serif;
}
</style>
