<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="'image-' + index"
          cols="12"
          sm="4"
        >
          <v-sheet class="ma-2 pa-4" style="width: 320px; height: 180px;">
            <v-img
              :src="image.url ? image.url : 'https://via.placeholder.com/128x128'"
              aspect-ratio="1"
              contain
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col v-for="(audio, index) in audios" :key="'audio-' + index" cols="12">
          <audio controls>
            <source :src="audio.url" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </v-col>
      </v-row>
    </v-container>

    <hr />

    <div>
      <v-text-field v-model="username" label="Enter your name" required></v-text-field>
      <v-text-field v-model="link" label="Enter your link" required></v-text-field>
      <v-btn @click="addLink" :disabled="!username.trim() || !link.trim()">Add Link</v-btn>
    </div>

    <hr />

    <div v-if="submissions.length > 0">
      <h2 class="text-center">Saved Links</h2>
      <v-list>
        <v-list-item v-for="(submission, index) in submissions" :key="index">
          <v-list-item-content>
            <v-list-item-title>
              <a :href="submission.content" target="_blank">{{ submission.filename }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const username = ref('')
    const link = ref('')
    const allowedFileType = ref('.txt')
    const submissions = ref([])

    const fetchSubmissions = async () => {
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week16_library_workshop')
          .list('', { limit: 1000 })

        if (submissionsError) {
          throw submissionsError
        }

        images.value = []
        audios.value = []
        submissions.value = []

        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week16_library_workshop')
            .download(file)

          if (urlError) {
            throw urlError
          }

          let fileUrl

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url);

            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.gif') || file.endsWith('.obj')) {
              images.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              audios.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.txt')) {
              // Load the content of the text file
              const { data: textContent, error: textError } = await supabase
                .storage
                .from('week16_library_workshop')
                .download(file)

              if (textError) {
                throw textError
              }

              submissions.value.push({ content: textContent, filename: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const addLink = async () => {
      if (!username.value.trim() || !link.value.trim()) {
        alert('Please enter both your name and link before submitting.')
        return
      }

      const timestamp = new Date().getTime()
      console.log('Username:', username.value)
      console.log('Timestamp:', timestamp)
      const fileName = `${username.value}_${timestamp}.txt`

      console.log('Link Value:', link.value)
      const blob = new Blob([link.value], { type: 'text/plain' })

      const { error: uploadError } = await supabase.storage
        .from('week16_library_workshop')
        .upload(fileName, blob)

      if (uploadError) {
        console.error('Error uploading link:', uploadError)
        return
      }

      console.log('Link added successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      username,
      link,
      addLink,
      allowedFileType,
      submissions
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
