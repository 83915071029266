<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">GDevelop - Sokoban Puzzle Game</h1>
    <img width="640" height="360" src="https://gdevelop.io/_next/image?url=https%3A%2F%2Fresources.gdevelop-app.com%2Fexamples%2Fsokoban%2Fpreview.png&w=1920&q=75">
    <p>Sokoban is a puzzle video game where the player pushes boxes around, trying to get them to specific locations. The game was designed in 1981 by Hiroyuki Imabayashi, and first published in December 1982.
This example shows a how to push multiple objects in a row, and how to check if the player has won. Check out the YouTube video explaining how this example was made.</p>
    <a href="https://editor.gdevelop.io/?project=https://resources.gdevelop-app.com/examples/sokoban/sokoban.json">Project File</a>
    <br><br>

    <br><hr>
    <h2>Start Here</h2>
    <iframe width="640" height="360" src="https://www.youtube.com/embed/BZnUC5cg3Po" title="GDevelop Tutorial - Remixing A Sokoban Template" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <br>
    <h2> Code / Level Design</h2>
    <iframe width="640" height="360" src="https://www.youtube.com/embed/B_smqRuRbfw" title="GDevelop Tutorial - Adding More Sokoban Levels" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <br>
    <h2> Game Art</h2>
    <iframe width="640" height="360" src="https://www.youtube.com/embed/ubr6of1RcgI" title="GDevelop Tutorial - Editing Sokoban Art Assets" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
 
    <hr />
    <br>
    <h2 class="text-center">Play The Games Here</h2>
    <br>
    <v-container>
    <v-row>
      <v-col v-for="student in students" :key="student.name" cols="12" md="4">
        <v-card>
          <v-card-title>{{ student.name }}</v-card-title>
          <v-card-subtitle>{{ student.game }}</v-card-subtitle>
          <v-card-text>
            <v-btn :href="student.gameLink" text color="primary">Play Game</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    return {
      students: [
        { name: 'Austin', game: 'Silly Position Sokoban', gameLink: 'https://gd.games/developer_50169/silly-position--sokoban-' },
        { name: 'Eva', game: 'Penguin Thing', gameLink: 'https://gd.games/chomkpenguino/penguin-thing' },
        { name: 'Mira', game: 'Mimibunny13 Sokoban', gameLink: 'https://gd.games/creatoregg2/mimibunny13--sokoban-' },
        { name: 'Dasha', game: 'Honorable Smash Sokoban', gameLink: 'https://gd.games/creator_egg_1/honorable-smash--sokoban-' },
        { name: 'Stirling', game: 'Fragile Ink Sokoban', gameLink: 'https://gd.games/stirl/fragile-ink--sokoban-' },
        { name: 'Nathaniel', game: 'Grandiose Skirt Sokoban', gameLink: 'https://gd.games/menameisnathaniel/grandiose-skirt--sokoban-' },
        { name: 'Tiari', game: 'Instant Builds', gameLink: 'https://gd.games/instant-builds/1ac71862-a4f0-4c9c-accc-92323db65a77' },
      ],
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
.hub-component {
  align-items: center;
  text-align: center;
}
</style>
