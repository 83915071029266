<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>
    <br>
    <a href="https://gd.games/instant-builds/e824be14-a01b-43ea-aa67-ed42a963d439">Daniel</a><br>
    <a href="https://gd.games/instant-builds/2eba9d1e-14e2-4561-b9bb-5746de9bce20">Thomas</a><br>
    <a href="https://gd.games/instant-builds/46922d3e-918f-4ef5-b613-cf77be5390d4">Erin</a><br>
    <a href="https://gd.games/instant-builds/9e62363c-25ef-4082-8119-a096dc88266c">Stephen</a><br>
    <a href="https://gd.games/instant-builds/78d551e1-98b2-4a06-b120-539fd26271c0">Lance</a><br>
    <a href="https://gd.games/instant-builds/4dfb3242-d0c9-4d05-a67c-01d602bdf8d4">Tiari</a><br>
    <a href="https://gd.games/instant-builds/5949e65a-8c5f-4f1a-af80-e9aee4e5ac62">Atlas</a><br>
    <a href="https://gd.games/instant-builds/a27f531f-52a5-4e87-b764-4c62368e116b">Callum</a><br>
    <a href="https://gd.games/instant-builds/c3bf1388-f9e1-4769-a1e7-9225f919305b">Max</a><br>
    <a href="https://gd.games/instant-builds/9f9d211d-1c62-4d5d-b6b5-3e0384df4695">Connor</a><br>
    <a href="https://gd.games/instant-builds/487182c2-7ade-47c3-9cfe-93d89d621571">Dayton</a><br>
    <a href="https://gd.games/instant-builds/8db55727-2906-4b6a-8742-ee3bd665d472">Foster</a><br>
    <a href="https://gd.games/instant-builds/edd78549-0e0b-48d5-b976-b782bd8d461b">Isabella</a><br>
    <a href="https://gd.games/instant-builds/93869e1d-edb2-4bb5-b7ac-2202352f7237">Austin</a><br>
    <a href="https://gd.games/instant-builds/46feeb81-d10e-41d4-ba4b-61fd88422a4d">Marcus</a><br>
</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'
//update
export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.png') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
            .download(file)

          console.log("Type of URL:", typeof url, url)

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url); // Assign the URL to fileUrl
            // Check if the file is an image or an audio file
            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.gif') || file.endsWith('.obj')) {
              images.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              //audios.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }


          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      rules,
      username,
      uploadFile,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
