import { createRouter, createWebHistory } from 'vue-router'
import Login from '../src/components/Login.vue'
import Profile from '../src/components/Profile.vue'
import Discover from '../src/components/Discover.vue'
import Home from '../src/components/Home.vue'
import Sub from '../src/components/Submissions.vue'
import HeroForge from '../src/components/HeroForge.vue'
import Bandlab from '../src/components/Bandlab.vue'
import Eggscene from '../src/components/Eggscene.vue'
import Workshop from '../src/components/BandlabWorkshop.vue'
import Landing from '../src/components/landing.vue'
import albumcover from '../src/components/albumcover.vue'
import week1workshop from '../src/components/week1libraryworkshop.vue'
import week2workshop from '../src/components/week2libraryworkshop.vue'
import week3workshop from '../src/components/week3libraryworkshop.vue'
import week4workshop from '../src/components/week4libraryworkshop.vue'
import week5workshop from '../src/components/week5libraryworkshop.vue'
import week6workshop from '../src/components/week6libraryworkshop.vue'
import week7workshop from '../src/components/week7libraryworkshop.vue'
import week8workshop from '../src/components/week8libraryworkshop.vue'
import week9workshop from '../src/components/week9libraryworkshop.vue'
import week10workshop from '../src/components/week10libraryworkshop.vue'
import week11workshop from '../src/components/week11libraryworkshop.vue'
import week12workshop from '../src/components/week12libraryworkshop.vue'
import week14workshop from '../src/components/week14libraryworkshop.vue'
import week15workshop from '../src/components/week15libraryworkshop.vue'
import week16workshop from '../src/components/week16_libraryworkshop.vue'
import week17workshop from '../src/components/week17_libraryworkshop.vue'
import week18workshop from '../src/components/week18libraryworkshop.vue'
import week3eggventurerworkshop from '../src/components/week3eggventurerworkshop.vue'
import week5eggventurerworkshop from '../src/components/week5eggventurerworkshop.vue'
import week7eggventurerworkshop from '../src/components/week7eggventurerworkshop.vue'
import week13workshop from '../src/components/week13libraryworkshop.vue'
import signup from '../src/components/create.vue'
import narrative from '../src/components/narrative.vue'
import week1wedworkshop from '../src/components/week1wedlibraryworkshop.vue'
import week2wedworkshop from '../src/components/week2wedlibraryworkshop.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/discover',
    name: 'Discover',
    component: Discover
  },
  {
    path: '/heroforge',
    name: 'HeroForge',
    component: HeroForge
  },
  {
    path: '/bandlab',
    name: 'Bandlab',
    component: Bandlab
  },
  {
    path: '/eggscene',
    name: 'Eggscene',
    component: Eggscene
  },
  {
    path: '/workshop',
    name: 'Workshop',
    component: Workshop
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/albumcovers',
    name: 'albumcover',
    component: albumcover
  },
  {
    path: '/doug',
    name: 'Sub',
    component: Sub
  },
  {
    path: '/profiles/:username',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/workshops/week1',
    name: 'WorkshopSubmissions',
    component: week1workshop
  },   
  {
    path: '/workshops/week2',
    name: 'WorkshopSubmissions2',
    component: week2workshop
  },   
  {
    path: '/workshops/week3',
    name: 'WorkshopSubmissions3',
    component: week3workshop
  },   
  {
    path: '/signup',
    name: 'Sign Up',
    component: signup
  },
  {
    path: '/workshops/week4',
    name: 'WorkshopSubmissions4',
    component: week4workshop
  },   
  {
    path: '/workshops/week5',
    name: 'WorkshopSubmissions5',
    component: week5workshop
  },
  {
    path: '/workshops/week7',
    name: 'WorkshopSubmissions7',
    component: week7workshop
  },
  {
    path: '/narrative',
    name: 'Narrative Games',
    component: narrative
  },
  {
    path: '/wed/week1',
    name: 'WorkshopSubmission1',
    component: week1wedworkshop
  },
  {
    path: '/wed/week2',
    name: 'WorkshopSubmission2',
    component: week2wedworkshop
  },
  {
    path: '/workshops/week6',
    name: 'WorkshopSubmission6',
    component: week6workshop
  },
  {
    path: '/workshops/week8',
    name: 'WorkshopSubmission8',
    component: week8workshop
  },
  {
    path: '/workshops/week9',
    name: 'WorkshopSubmission9',
    component: week9workshop
  },
  {
    path: '/workshops/week10',
    name: 'WorkshopSubmission10',
    component: week10workshop
  },
  {
    path: '/workshops/week11',
    name: 'WorkshopSubmission11',
    component: week11workshop
  },
  {
    path: '/workshops/week12',
    name: 'WorkshopSubmission12',
    component: week12workshop
  },
  {
    path: '/workshops/eggventurers/week3',
    name: 'WorkshopSubmissionEggventurer1',
    component: week3eggventurerworkshop
  },
  {
    path: '/workshops/week13',
    name: 'WorkshopSubmission13',
    component: week13workshop
  },
  {
    path: '/workshops/week14',
    name: 'WorkshopSubmission14',
    component: week14workshop
  },
  {
    path: '/workshops/week15',
    name: 'WorkshopSubmission15',
    component: week15workshop
  },
  {
    path: '/workshops/eggventurers/week6',
    name: 'WorkshopSubmissionEggventurer2',
    component: week5eggventurerworkshop
  },
  {
    path: '/workshops/eggventurers/topdown',
    name: 'WorkshopSubmissionEggventurer7',
    component: week7eggventurerworkshop
  },
  {
    path: '/workshops/week16',
    name: 'WorkshopSubmission16',
    component: week16workshop
  },
  {
    path: '/workshops/week17',
    name: 'WorkshopSubmission17',
    component: week17workshop
  },
  {
    path: '/workshops/week18',
    name: 'WorkshopSubmission18',
    component: week18workshop
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
