<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="'image-' + index"
          cols="12"
          sm="4"
        >
          <v-card>
            <v-img
              :src="image.url ? image.url : 'https://via.placeholder.com/128x128'"
              aspect-ratio="1"
              contain
            ></v-img>
            <div v-if="image.backstory">{{ image.backstory }}</div>
          </v-card>
        </v-col>
        <v-col v-for="(audio, index) in audios" :key="'audio-' + index" cols="12">
          <audio controls>
            <source :src="audio.url" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </v-col>
      </v-row>
      <br>
      <div>
        <v-text-field v-model="username" label="Enter your name" required></v-text-field>
        <v-textarea v-model="backstory" label="Enter your backstory"></v-textarea>
      </div>
    </v-container>
  </div>
  <hr />
  <!--
  <div>
    <v-file-input
      :rules="rules"
      :accept="allowedFileType"
      placeholder="Pick a file"
      prepend-icon="mdi-upload"
      label="Click To Upload..."
      @change="uploadFile"
      multiple
    ></v-file-input>
  </div> -->
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([]);
    const audios = ref([]);
    const error = ref(null);
    const rules = ref([]);
    const username = ref('');
    const backstory = ref('');
    const allowedFileType = ref('.png');

    const fetchSubmissions = async () => {
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week11_library_workshop')
          .list('', { limit: 1000 })

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls

        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week11_library_workshop')
            .download(file);

          let fileUrl;
          let backstoryText = '';

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url);

            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.gif')) {
              // Only add images to the array
              images.value.push({ url: fileUrl, name: file });
            } else if (file.endsWith('.txt')) {
              // If it's a text file, download and store the backstory text
              const reader = new FileReader();
              reader.onload = (event) => {
                backstoryText = event.target.result;
                images.value[images.value.length - 1].backstory = backstoryText;
              };
              reader.readAsText(url);
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url);
          }

          if (urlError) {
            throw urlError;
          }
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      const backstoryText = backstory.value;

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week11_library_workshop') // Use 'week5_library_workshops' bucket
          .upload(filePath, file)

          const textFileName = `${username.value}_${timestamp}.txt`;
        const textFileContent = `Name: ${username.value}\nBackstory: ${backstoryText}`;

        const { error: textUploadError } = await supabase.storage
          .from('week11_library_workshop')
          .upload(textFileName, textFileContent, { contentType: 'text/plain' });

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions);

    return {
      images,
      error,
      rules,
      username,
      backstory,
      uploadFile,
      allowedFileType,
    };
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}

/* Add new styles for image and backstory */
.v-sheet {
  position: relative; /* Ensure the container is positioned relatively */
}

.v-sheet .backstory {
  position: absolute;
  bottom: 10px; /* Adjust the positioning as needed */
  left: 10px; /* Adjust the positioning as needed */
  background: rgba(255, 255, 255, 0.8); /* Add a semi-transparent background for readability */
  padding: 10px; /* Add padding for readability */
  border: 1px solid #ccc; /* Add a border for separation */
  border-radius: 5px; /* Add rounded corners */
  max-width: 80%; /* Limit the width of the backstory box */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
}
</style>