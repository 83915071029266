<template>
    <v-app>
      <v-main>
        <!-- Hero Section -->
        <v-parallax
          dark
          src="path/to/hero-image.jpg"
          height="600"
        >
          <v-container>
            <v-layout
              align-center
              justify-center
              column
            >
              <h1 class="display-2 text-center">Welcome to The Egg Academy</h1>
              <h2 class="subheading text-center">Unlock Your Creative Potential</h2>
              <v-btn
                color="primary"
                dark
                large
                class="mt-5"
              >
                Get Started
              </v-btn>
            </v-layout>
          </v-container>
        </v-parallax>
  
        <!-- About Section -->
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <h2 class="headline text-center mb-3">About Us</h2>
              <p class="text-center">
                The Egg Academy is a vibrant hub of creativity where young people aged 11 and above can connect, learn, and express themselves through digital creative skills.
              </p>
              <p class="text-center">
                Our mission is to empower and inspire the next generation of digital artists, designers, and storytellers. Join our community and unlock your creative potential!
              </p>
            </v-flex>
            <v-flex xs12 sm6>
              <v-img
                src="path/to/about-image.jpg"
                aspect-ratio="1"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>
  
        <!-- Workshops Section -->
        <v-parallax
          dark
          src="path/to/workshops-image.jpg"
          height="500"
        >
          <v-container>
            <v-layout
              align-center
              justify-center
              column
            >
              <h2 class="display-1 text-center">Join Our Workshops</h2>
              <h3 class="subheading text-center">Expand Your Digital Skills</h3>
              <v-btn
                color="primary"
                dark
                large
                class="mt-5"
              >
                View Workshops
              </v-btn>
            </v-layout>
          </v-container>
        </v-parallax>
  
        <!-- Creative Challenges Section -->
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-img
                src="path/to/challenges-image.jpg"
                aspect-ratio="1"
              ></v-img>
            </v-flex>
            <v-flex xs12 sm6>
              <h2 class="headline text-center mb-3">Creative Challenges</h2>
              <p class="text-center">
                Participate in our creative challenges and push the boundaries of your imagination. Each challenge provides an opportunity to explore new ideas, experiment with different art styles, and showcase your talent.
              </p>
              <p class="text-center">
                Unleash your creativity, complete the challenges, and earn recognition for your exceptional work.
              </p>
            </v-flex>
          </v-layout>
        </v-container>
  
        <!-- Celebrate Creativity Section -->
        <v-parallax
          dark
          src="path/to/celebration-image.jpg"
          height="500"
        >
          <v-container>
            <v-layout
              align-center
              justify-center
              column
            >
              <h2 class="display-1 text-center">Celebrate Creativity</h2>
              <h3 class="subheading text-center">Showcase Your Artistic Achievements</h3>
              <v-btn
                color="primary"
                dark
                large
                class="mt-5"
              >
                Explore Gallery
              </v-btn>
            </v-layout>
          </v-container>
        </v-parallax>
  
        <!-- Contact Section -->
        <v-container>
          <h2 class="headline text-center mb-3">Contact Us</h2>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Phone</v-list-item-title>
                        <v-list-item-subtitle>+1 234 567 890</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle>info@eggacademy.com</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-map-marker</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Address</v-list-item-title>
                        <v-list-item-subtitle>123 Egg Street, Cityville, Country</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6>
              <v-card>
                <v-card-text>
                  <v-form>
                    <v-text-field label="Name"></v-text-field>
                    <v-text-field label="Email"></v-text-field>
                    <v-textarea label="Message"></v-textarea>
                    <v-btn
                      color="primary"
                      dark
                      large
                      class="mt-5"
                    >
                      Send Message
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>
  
  <style scoped>
  /* Adjust styling based on your preferences */
  .headline {
    color: #663399;
  }
  
  .subheading {
    color: #9c27b0;
  }
  
  .parallax__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-5 {
    margin-top: 5rem;
  }
  </style>
  