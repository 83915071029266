<template>
  <div>
    <v-container>
      <v-row>
        <v-col
    v-for="(image, index) in images"
    :key="'image-' + index"
    cols="12" sm="6" md="4" lg="3"
  >
    <div class="image-container">
      <v-img 
        class="image"
        :src="image.url ? image.url : 'https://via.placeholder.com/128x128'" 
        @click="openDialog(index)"
      ></v-img>
      <div class="image-label">Click to view</div>
    </div>
  </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" hide-overlay max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ selectedImage.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="dialog-container">
          <v-img :src="selectedImage.url" aspect-ratio="1" class="dialog-image"></v-img>
          <p class="dialog-text">{{ backstories[selectedImageIndex].text }}</p>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const backstories = ref([])
    const error = ref(null)
    const dialog = ref(false)
    const selectedImage = ref({})
    const selectedImageIndex = ref(null)
    
    const openDialog = (index) => {
      selectedImage.value = images.value[index]
      selectedImageIndex.value = index
      dialog.value = true
    }
    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
    const { data: backstoryData, error: backstoryError } = await supabase
      .storage
      .from('week2_library_workshop_backstories')
      .list('', { limit: 1000 })

    if (backstoryError) {
      throw backstoryError
    }

    backstories.value = [] // Clear the array before adding new URLs
    for (const { name: file } of backstoryData) {
      const { data: url, error: urlError } = await supabase
        .storage
        .from('week2_library_workshop_backstories')
        .download(file)

      if (urlError) {
        throw urlError
      }

      // Use a Promise to handle the FileReader asynchronous nature
      const fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          resolve(e.target.result);
        };
        reader.onerror = function(e) {
          reject("Error reading file");
        };
        reader.readAsText(url);
      });

      backstories.value.push({ text: fileContent, name: file })
    }
  } catch (error) {
    console.error('Error retrieving submissions:', error)
    error.value = error.message || 'Error retrieving submissions'
  }
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week2_library_workshop')
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week2_library_workshop')
            .download(file)

            const fileUrl = URL.createObjectURL(url)
            console.log('Created blob URL:', fileUrl)
            console.log("BREAK")

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)

          if (urlError) {
            throw urlError
          }

          // Check if the file is an image or an audio file
          if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg')) {
            images.value.push({ url: fileUrl, name: file })
          } else if (file.endsWith('.mp3')) {
            audios.value.push({ url: fileUrl, name: file })
          }
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const fileExt = file.name.split('.').pop()
          const filePath = `heroforge_`+`${i + 1}.${fileExt}`

          const { error: uploadError } = await supabase.storage
            .from('heroforge')
            .upload(filePath, file)

          if (uploadError) throw uploadError
        }
        
        console.log('File uploaded successfully!')
        location.reload();
      } catch (error) {
        console.error('Error uploading file:', error.message)
      }
    }

    onMounted(fetchSubmissions)
    return {
      backstories,
      images,
      audios,
      error,
      dialog,
      selectedImage,
      selectedImageIndex,
      openDialog
    }
  }
}
</script>
<style scoped>
.text-center {
  text-align: center;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dialog-image {
  max-height: 50vh;
}

.dialog-text {
  max-width: 80%;
  text-align: center;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  /*padding-bottom: 100%;*/ /* for aspect ratio */
  background-size: cover;
  background-position: center;
  border-radius: 4px; /* optional, if you want rounded corners */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); /* optional, for elevation effect */
  cursor: pointer;
}

.image-label {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>