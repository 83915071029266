<template>
    <div class="hub-component">
      <h1 class="text-center">Youtube</h1>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" v-for="n in 3" :key="n">
            <v-card class="pa-2">
              <v-card-title>Card {{ n }}</v-card-title>
              <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "Hub"
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: left;
  }
  </style>
  