<template>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="pa-4" outlined>
            <v-card-text>
              <h1 class="text-center mb-4">Sign Up!</h1>
              <p>Please enter your email and password to create an account.</p>
              <v-text-field
                v-model="email"
                :counter="50"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :counter="50"
                :rules="passwordRules"
                label="Password"
                type="password"
                required
              ></v-text-field>
              <v-btn color="primary" @click="signup">Sign Up</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { supabase } from '../plugins/supabase'
  
  export default {
    name: 'SignUp',
    setup() {
      const email = ref('')
      const password = ref('')
      const emailRules = [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ]
      const passwordRules = [v => !!v || 'Password is required']
  
      const router = useRouter()
  
      const signup = async () => {
        try {
          const { data, error } = await supabase.auth.signUp({
            email: email.value,
            password: password.value,
          });
  
          if (error) throw error
          alert("Account created successfully!")
          console.log('User:', data)
          
          // After signup, navigate to the login page or anywhere else you see fit
          router.push({ name: 'Login' })
  
        } catch (error) {
          console.error('Error: ', error.message)
        }
      }
  
      return {
        email,
        password,
        emailRules,
        passwordRules,
        signup
      }
    },
  }
  </script>
  
  <style scoped>
  h1 {
    font-family: 'League Spartan', sans-serif;
  }
  </style>
  