<template>
  <div class="hub-component">
    <br /><br />
    <br /><br /><br /><br />
    <h1 class="text-center">Game Submissions</h1>
    <p>Click on the student's name to be taken to their game!</p> <br>
    <h4>The controlls are Arrow Keys to move + Spacebar to jump</h4>
    <a href="https://gd.games/instant-builds/f3f160b6-e2cf-4f5e-9f88-69e7070f2d37" target="_blank">Dayton</a> <br>
    <a href="https://gd.games/instant-builds/3216b2b0-b919-4169-bb56-7da68a47a452" target="_blank">Yuju</a> <br>
    <a href="https://gd.games/games/311ffe33-bfc0-4642-b740-e5bc934b8ea2" target="_blank">Dayoon</a> <br>
    <a href="https://gd.games/menameisnathaniel/breakable-poison--platformer-" target="_blank">Nathaniel</a> <br>
    <a href="https://gd.games/jamieaalienfromspace/jamies-alien-world" target="_blank">Jamie</a> <br>
    <a href="https://gd.games/sushiking3/sushi-king-is-cool" target="_blank">Thomas</a> <br>
    <a href="https://gd.games/matthewif/felix-s-platformer" target="_blank">Matthew</a> <br>
    <a href="https://gd.games/instant-builds/2633d57f-5daf-4c80-bf53-f30ac7bfd95b" target="_blank">Trent</a><br>
    <a href="https://gd.games/im_not_here/gnarly-trees--platformer--isab" target="_blank">Isabella</a><br>
    <a href="https://gd.games/infernoplex/egg-academy-irl-game-thingy-m8" target="_blank">Tiari</a><br>
    <a href="https://gd.games/instant-builds/fe7f4dc7-66ad-4ec9-9f1d-5a0aa242a590" target="_blank">Max</a><br>
    <a href="https://gd.games/stirlpog/childlike-stamp--platformer-" target="_blank">Stirling</a><br>
    <a href="https://gd.games/goosemaluc/goosemaluc-lucy" target="_blank">Lucy</a><br>
    <a href="https://gd.games/travisthemugiu/nippy-crow--platformer-" target="_blank">Travis</a><br>
    <a href="https://gd.games/instant-builds/091553f1-0c0d-49e4-a707-f9545a44025c" target="_blank">Theo</a><br> 
    <a href="https://gd.games/instant-builds/0b988b91-96eb-4d09-aa96-6a30cc44dd10" target="_blank">Austin</a><br>
    <a href="https://gd.games/games/6bf870a9-e4da-42ff-82d8-f72a8129257b" target="_blank">Jonny</a><br>
    <a href="https://gd.games/games/39b3a7c5-65bf-4bdc-bcd2-f46c3461ba94" target="_blank">Dasha</a><br>
    <a href="https://gd.games/duckmaster47/prime-jump" target="_blank">Milan</a><br>
    <a href="https://gd.games/instant-builds/154ffae4-dfd2-45d2-8356-9a861121796b" target="_blank">Rome</a><br>
    <a href="https://gd.games/nooby_dude144/plocky" target="_blank">Tom</a><br>
    <a href="https://gd.games/floppy52555/mimi-bunny--mira----floppy-hop" target="_blank">Mira</a><br>
  </div>
  <hr />
</template>

<script>
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
