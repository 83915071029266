<template>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278515"><a href="https://egg-academy-student-work.itch.io/uwwuwuuwuwwu-by-archie">uwwuwuuwuwwu by Archie by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2278519" width="552"><a href="https://egg-academy-student-work.itch.io/untit">Untitled by Celine by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278524"><a href="https://egg-academy-student-work.itch.io/dayoon">Dayoon&#039;s Game by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278526"><a href="https://egg-academy-student-work.itch.io/isabella">Egg Adventure by Isabella by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278530"><a href="https://egg-academy-student-work.itch.io/jackson">A Story by Jackson by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278534"><a href="https://egg-academy-student-work.itch.io/jamie">A Hard Choice by Jamie by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278537"><a href="https://egg-academy-student-work.itch.io/jasmine">Jasmine&#039;s Game by Egg Academy Student Work</a></iframe>
<iframe src="https://itch.io/embed/2278468" width="552" height="167" frameborder="0"><a href="https://egg-academy-student-work.itch.io/maggie">FNAF by Maggie by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278539"><a href="https://egg-academy-student-work.itch.io/max">1 v 100 by Max by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278542"><a href="https://egg-academy-student-work.itch.io/monique">Untitled by Monique by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278511"><a href="https://egg-academy-student-work.itch.io/nathaniel">men by Nathaniel by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278547"><a href="https://egg-academy-student-work.itch.io/rome">1 legionnaire vs 100 barbarians by Rome by Egg Academy Student Work</a></iframe>
<iframe src="https://itch.io/embed/2278550" width="552" height="167" frameborder="0"><a href="https://egg-academy-student-work.itch.io/stepan">Fencing Fight by Stepan by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2278553" width="552"><a href="https://egg-academy-student-work.itch.io/stirling">nathy by Stirling by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278556"><a href="https://egg-academy-student-work.itch.io/tiari">1 vs 100 by Tiari by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278558"><a href="https://egg-academy-student-work.itch.io/travis">Smuff Cat by Travis by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2278562"><a href="https://egg-academy-student-work.itch.io/vicky">Vicky&#039;s Game by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2278565" width="552"><a href="https://egg-academy-student-work.itch.io/yuju">Untitled by Yuju by Egg Academy Student Work</a></iframe>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const files = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.twee') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week6_library_workshop')
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        files.value = [] // Clear the array before adding new URLs

        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week6_library_workshop')
            .download(file)

          console.log("Type of URL:", typeof url, url)

          if (url instanceof Blob && url.size > 0) {
            const fileUrl = URL.createObjectURL(url)
            if (file.endsWith('.twee')) {
              files.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }

          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFiles = async (evt) => {
      const filesToUpload = evt.target.files
      if (!filesToUpload || filesToUpload.length === 0) return

      if (username.value.trim() === '') {
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime()
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        const { error: uploadError } = await supabase.storage
          .from('week6_library_workshop')
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      files,
      error,
      rules,
      username,
      uploadFiles,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
