<template>
  <div>
    <v-alert
      class="ma-4"
      type="success"
      title="Sid's Trials [Complete]"
      text="Congratulations on completing Sid's Trials, you can now upload creative work any time to your profile! Happy Creating."
    ></v-alert>
    <v-row class="ma-4">
      <v-avatar color="primary" size="x-large"> J </v-avatar>
      <h1 class="pa-4" v-if="profile">{{ profile.username }}</h1>
      <h1 v-else>No User</h1>
    </v-row>
    Image Creations
    <v-container>
      <v-row no-gutters v-if="profile">
        <v-col
          v-for="(imageUrl, index) in imageUrls.slice(0, 11)"
          :key="index"
          cols="2"
          sm="2"
        >
          <v-sheet class="ma-2 pa-4" style="width: 256px; height: 256px;">
            <v-img :src="imageUrl ? imageUrl : 'https://via.placeholder.com/128x128'" aspect-ratio="1" contain></v-img>
          </v-sheet>
        </v-col>
      </v-row>
      Music Creations
      <!-- Audio Display Area -->
      <v-row no-gutters v-if="profile">
        <v-col
          v-for="(audioUrl, index) in audioUrls.slice(0, 11)"
          :key="index"
          cols="2"
          sm="2"
        >
          <audio controls :src="audioUrl ? audioUrl : ''" style="width: 100%"></audio>
        </v-col>
      </v-row>
      Video Creations
      <!-- Video Display Area -->
      <v-row no-gutters v-if="profile">
        <v-col
          v-for="(videoUrl, index) in videoUrls.slice(0, 11)"
          :key="index"
          cols="2"
          sm="2"
        >
          <video controls :src="videoUrl ? videoUrl : ''" style="width: 100%"></video>
        </v-col>
      </v-row>
    </v-container>
    <EditProfile v-if="isCurrentUser" />
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { supabase } from '../plugins/supabase'
import EditProfile from './EditProfile.vue'

export default {
  name: 'Profile',
  components: {
    EditProfile
  },
  setup() {
    const route = useRoute()
    const profile = ref(null)
    const user = ref(null)
    const imageUrls = ref([])
    const audioUrls = ref([])
    const videoUrls = ref([])
    const error = ref(null)
    let oldMedia = null

    const fetchProfileMedia = async () => {
      try {
        const { data, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('username', route.params.username)
          .single()

        if (profileError) {
          throw profileError
        }

        profile.value = data
        const { data: userData } = await supabase.auth.getUser()
        user.value = userData?.user

        const currentMedia = profile.value.images.concat(profile.value.audios, profile.value.videos);

        // Check if media have changed. If not, return.
        if (JSON.stringify(currentMedia) === JSON.stringify(oldMedia)) {
          return;
        }

        oldMedia = currentMedia.length ? [...currentMedia] : [];

        // Fetch public URLs for images
        imageUrls.value = []
        if (profile.value.images) {
          for (const image of profile.value.images) {
            const { data: url, error: urlError } = await supabase
              .storage
              .from('images')
              .download(image)

            if (urlError) {
              throw urlError
            }
            if (image.endsWith('.mp4')) {
      videoUrls.value.push(URL.createObjectURL(url))
    } else if (image.endsWith('.mp3')) {
      audioUrls.value.push(URL.createObjectURL(url))
    } else {
      imageUrls.value.push(URL.createObjectURL(url))
    }
          }
        }                      
        // Set up watcher inside fetchProfileMedia to ensure profile.value is not null
        watch(
          () => [profile.value?.images, profile.value?.audios, profile.value?.videos],
          fetchProfileMedia,
          { deep: true }
        )

      } catch (error) {
        console.error('Error retrieving profile:', error)
        error.value = error.message || 'Error retrieving profile'
      }
    }

    onMounted(fetchProfileMedia)

    const isCurrentUser = computed(() => {
      return user.value && profile.value && user.value.id === profile.value.id
    })

    return {
      profile,
      isCurrentUser,
      imageUrls,
      audioUrls,
      videoUrls,
      error
    }
  }
}
</script>

<style scoped>
h1 {
  font-family: 'League Spartan', sans-serif;
}
</style>
