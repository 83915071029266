<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>
    <br>
    <a href="https://gd.games/instant-builds/b561d5cf-31f9-41db-92f9-a3ccd0d1903b">Daniel</a><br>
    <a href="https://gd.games/instant-builds/3d7a3439-4787-406d-a01f-b3daf35f0b8f">Thomas</a><br>
    <a href="https://gd.games/instant-builds/73a0118d-3349-4b52-8cc6-5d0e452c7580">Erin</a><br>
    <a href="https://gd.games/instant-builds/c4fb5568-e8d9-4eda-8271-6c14ff6ee528">Stephen</a><br>
    <a href="https://gd.games/instant-builds/6f83d61c-3925-45d6-acad-36ea96d91bfd">Lance</a><br>
    <a href="https://gd.games/instant-builds/240b4a23-8fbd-4f89-9f04-c65f48159d6d">Tiari</a><br>
    <a href="https://gd.games/instant-builds/bf697c03-163a-4533-986c-53b9ea34b891">Atlas</a><br>
    <a href="https://gd.games/instant-builds/2d67c096-949d-4ede-9f45-8c180bf70cf7">Callum</a><br>
    <a href="https://gd.games/instant-builds/893e1359-fdf8-40ee-aa22-ca3eeba3c783">Max</a><br>
    <a href="https://gd.games/instant-builds/f379c09e-76bb-49a7-9471-a7170eff9d16">Connor</a><br>
    <a href="https://gd.games/instant-builds/ddbb6b5b-8bc8-4f56-88a5-c6617af803c1">Dayton</a><br>
    <a href="https://gd.games/instant-builds/0650adb7-4fb2-408c-9c5c-4da5a6416cf2">Foster</a><br>
    <a href="https://gd.games/instant-builds/3dfc7356-e744-41fb-b063-edfd42bb8d2e">Isabella</a><br>
    <a href="https://gd.games/instant-builds/c1a3ec99-673b-4dbf-bd19-81de2d480e92">Austin</a><br>
    <a href="https://gd.games/instant-builds/84227937-888d-4a61-9b94-29e5206241e8">Marcus</a><br>
    <a href="https://gd.games/stoomped/odacava-and-the-fiesty-fiends-">Beck</a>
</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'
//update
export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.png') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .list('', { limit: 1000 })

        console.log('Listed submissions:', submissionsData)
        console.log('List submissions error:', submissionsError)

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
            .download(file)

          console.log("Type of URL:", typeof url, url)

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url); // Assign the URL to fileUrl
            // Check if the file is an image or an audio file
            if (file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.gif') || file.endsWith('.obj')) {
              images.value.push({ url: fileUrl, name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              //audios.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }


          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
          console.log('Download file error:', urlError)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week12_library_workshop') // Use 'week5_library_workshops' bucket
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      images,
      audios,
      error,
      rules,
      username,
      uploadFile,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
