<template>
    <div class="hub-component">
      <h1 class="text-center">Creative Hub</h1>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card class="pa-2">
              <v-img src="../assets/Challenges.png" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Creative Challenges</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
            <br>
            <v-card class="pa-2">
              <v-img src="https://via.placeholder.com/500" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Skill Building</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2">
              <v-img src="../assets/Cooperative.png" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Co-operative</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
            <br>
            <v-card class="pa-2">
              <v-img src="https://via.placeholder.com/500" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Showcase</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="pa-2">
              <v-img src="../assets/Events.png" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Events</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
            <br>
            <v-card class="pa-2">
              <v-img src="https://via.placeholder.com/500" aspect-ratio="1"></v-img>
              <v-card-title class="card-title">Portfolios</v-card-title>  
              <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    name: "Hub"
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  