<template>
    <v-app-bar app color="transparent" flat>
      <div class="navbar-content">
        <div class="d-flex align-center justify-start">
          <!-- Logo -->
            <v-img src="../assets/logo.svg" max-width="40" class="mr-2"></v-img>
            <div class="text-purple">
              <span>Egg</span>
              <br/>
              <span>Academy</span>
            </div>
        </div>
  
        <div class="d-flex align-center justify-center">
          <!-- Navigation Links -->
          <v-btn text to="/" class="text-purple">Home</v-btn>
          <v-btn text to="/" class="text-purple">Connect</v-btn>
          <v-btn text to="/heroforge" class="text-purple">Challenge</v-btn>
          <v-btn text to="/" class="text-purple">Discover</v-btn>
        </div>
        <!--
        <div class="d-flex align-center justify-end">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="text-purple">
                  <v-img src="../assets/egg.png"></v-img>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-purple">
                    Hi, Username
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
  
          <v-btn color="light-blue darken-1" to="/myprofile" class="text-purple">My Profile</v-btn>
        </div>
        -->
      </div>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    name: 'Navbar',
    data: () => ({
      drawer: false,
    }),
  };
  </script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
  }
  
  .text-purple {
    color: purple;
  }
  
  .navbar-content {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
  </style>
  