<template>
    <Hero></Hero>
    <hr>
    <Hub></Hub>
    <hr>
    <Youtube></Youtube>
    <br>
    <COTW></COTW>
</template>
  
  <script>
  import Hero from './Hero.vue';
  import Hub from './Hub.vue';
  import Youtube from './Youtube.vue';
  import COTW from './COTW.vue';
  export default {
    name: 'Home',
    components: {
        Hero,
        Hub,
        Youtube,
        COTW
    }
  }
  </script>
  
  <style scoped>
  h1 {
    font-family: 'League Spartan', sans-serif;
  }
  </style>
  