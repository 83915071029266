<template>
    <div class="cotw-content">
      <div class="left-column">
        <v-card>
          <v-img src="https://via.placeholder.com/500" aspect-ratio="1"></v-img>
          <v-card-text>Lorem ipsum dolor sit amet.</v-card-text>
        </v-card>
      </div>
      <div class="right-column">
        <h1>Header Text Here</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac ornare nisl.</p>
        <div class="buttons">
          <v-btn color="purple" dark class="mr-2">Button 1</v-btn>
          <v-btn outlined class="text-purple">Button 2</v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "COTW",
  };
  </script>
  
  <style scoped>
  .cotw-content {
    display: flex;
    margin: 0 auto;
    max-width: 60%;
  }
  
  .left-column, .right-column {
    flex: 1;
  }
  
  .buttons {
    display: flex;
    justify-content: start;
    gap: 1rem;
  }
  
  .text-purple {
    color: purple;
  }
  </style>
  