<template>
  <iframe src="https://itch.io/embed/2268762" width="552" height="167" frameborder="0"><a href="https://infernodev.itch.io/zordos">A New Life - Zordos Short Interactive Story by Infernodev</a></iframe>
  <iframe frameborder="0" src="https://itch.io/embed/2268767" width="552" height="167"><a href="https://maxpayton.itch.io/twine-game">Twin by Maxpayton</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2268763"><a href="https://phtevenincredible.itch.io/stephens-zordos-adventure-of-death">Stephen&#039;s Zordos adventure of death. by PhtevenIncredible</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2268775"><a href="https://berzerkerman.itch.io/lances-secrets">LANCES SECRETS? by BERZERKER.MAN</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2268773"><a href="https://sleepyhalo.itch.io/s">Zordos Erin by sleepyhalo</a></iframe>
  <iframe frameborder="0" src="https://itch.io/embed/2268768" width="552" height="167"><a href="https://chadwick312.itch.io/eggy-story-game">Eggy Story Game by chadwick312</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2268765"><a href="https://the-porg.itch.io/daniels-final-zordos-game">Daniel&#039;s Final Zordos game by The_Porg</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2268761"><a href="https://kanestewart.itch.io/kanes-final-zordos-game">Kane&#039;s Final Zordos Game by kanestewart</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2270666"><a href="https://dtacat.itch.io/games-cat">Games.cat by DTACat</a></iframe>
  <iframe frameborder="0" src="https://itch.io/embed/2270696" width="552" height="167"><a href="https://astral-atlas.itch.io/zordos-mask-fight-minigame">Zordos Mask Fight Minigame by Astral_Atlas</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2270699"><a href="https://bobslayer2000.itch.io/backroom-zordos-bobslayer">Backroom Zordos by Bobslayer2000</a></iframe>
  <iframe src="https://itch.io/embed/2270702" width="552" height="167" frameborder="0"><a href="https://smokywhale.itch.io/fosters-zordos-adventure">Foster&#039;s Zordos adventure by SmokyWhale</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2270700"><a href="https://peacectrlbots.itch.io/the-falling-of-amcaster">The Falling Of Amcaster by PeaceCtrlBOTs</a></iframe>
  <iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2270701"><a href="https://thepotato32.itch.io/funny-haha-maze">maze (fun) by ThePotato32</a></iframe>
  </template>
  
  <script>123796558
  export default {
    name: "Narrative"
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  