<template>
    <div class="hero-content">
      <div class="left-column">
        <div class="image-with-text">
          <v-img
            src="../assets/Hero_Header.png"
            aspect-ratio="2.81"
          />
          <h1 class="over-image-text">Connect Through Creativity</h1>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac ornare nisl.</p>
        <div class="buttons">
          <v-btn color="purple" dark class="mr-2">Button 1</v-btn>
          <v-btn outlined class="text-purple">Button 2</v-btn>
        </div>
      </div>
      <div class="right-column">
        <v-img
          src="../assets/egg.png"
          aspect-ratio="0.82"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Hero"
  };
  </script>
  
  <style scoped>
  .hero-content {
    display: flex;
    margin: 0 auto;
    max-width: 60%;
  }
  
  .left-column, .right-column {
    flex: 1;
  }
  
  .image-with-text {
    position: relative;
  }
  
  .over-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #472852;
    font-size: 3em;
    text-align: left;
  }
  
  .buttons {
    display: flex;
    justify-content: start;
    gap: 1rem;
  }
  
  .text-purple {
    color: purple;
  }
  </style>
  