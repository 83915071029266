<template>
  <div class="hub-component">
    <br /><br />
    <h1 class="text-center">Submissions</h1>

<iframe height="167" frameborder="0" src="https://itch.io/embed/2387421" width="552"><a href="https://egg-academy-student-work.itch.io/archiegameshow">Archie&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2387433"><a href="https://egg-academy-student-work.itch.io/austins-game-show">Austin&#039;s Game Show by Egg Academy Student Work</a></iframe> 
<iframe height="167" frameborder="0" src="https://itch.io/embed/2387439" width="552"><a href="https://egg-academy-student-work.itch.io/dayoons-game-show">Dayoon&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2387443"><a href="https://egg-academy-student-work.itch.io/jacksons-game-show">Jackson&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2387445"><a href="https://egg-academy-student-work.itch.io/yujus-game-show">Yuju&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe width="552" height="167" frameborder="0" src="https://itch.io/embed/2387447"><a href="https://egg-academy-student-work.itch.io/joshs-game-show">Josh&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2387449" width="552"><a href="https://egg-academy-student-work.itch.io/maggies-game-show">Maggie&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2387452" width="552"><a href="https://egg-academy-student-work.itch.io/miras-game-show">Mira&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2387453" width="552"><a href="https://egg-academy-student-work.itch.io/stirlings-game-show">Stirling&#039;s Game Show by Egg Academy Student Work</a></iframe>
<iframe height="167" frameborder="0" src="https://itch.io/embed/2387455" width="552"><a href="https://egg-academy-student-work.itch.io/tiaris-game-show">Tiari&#039;s Game Show by Egg Academy Student Work</a></iframe>
<div></div>
<!-- 
<div
          v-for="(twineFile, index) in twineFiles"
          :key="'twine-file-' + index"
          cols="12"
          sm="4">
            <div>{{ twineFile.name }}</div>
      </div>
  </div>
  <hr />
  <div>
    <v-text-field v-model="username" label="Enter your name" required></v-text-field>
    <v-file-input
      :rules="rules"
      :accept="allowedFileType"
      placeholder="Pick a file"
      prepend-icon="mdi-upload"
      label="Click To Upload..."
      @change="uploadFile"
      multiple
    ></v-file-input>-->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const twineFiles = ref([])
    const audios = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const username = ref('') // Add user name ref
    const allowedFileType = ref('.twee') // Specify allowed file type

    const fetchSubmissions = async () => {
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week15') // Use 'week15' bucket
          .list('', { limit: 1000 })

        if (submissionsError) {
          throw submissionsError
        }

        twineFiles.value = [] // Clear the array before adding new filenames
        audios.value = [] // Do the same for audioUrls
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week15') // Use 'week15' bucket
            .download(file)

          if (urlError) {
            throw urlError
          }

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url); // Assign the URL to fileUrl
            // Check if the file is an .twine file
            if (file.endsWith('.twee')) {
              twineFiles.value.push({ name: file })
            } else if (file.endsWith('.mp3') || file.endsWith('.wav') || file.endsWith('.m4a')) {
              audios.value.push({ url: fileUrl, name: file })
            }
          } else {
            console.error("URL is not a valid blob or is empty:", url)
          }
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files
      if (!files || files.length === 0) return

      if (username.value.trim() === '') {
        // Prompt user to enter their name
        alert('Please enter your name before uploading.')
        location.reload()
        return
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileExt = file.name.split('.').pop()
        const timestamp = new Date().getTime() // Get current timestamp
        let filePath = `${username.value}_${timestamp}.${fileExt}`

        // Upload the file with the unique filePath
        const { error: uploadError } = await supabase.storage
          .from('week15') // Use 'week15' bucket
          .upload(filePath, file)

        if (uploadError) {
          console.error("Error uploading file:", uploadError)
          return
        }
      }

      console.log('Files uploaded successfully!')
      location.reload()
    }

    onMounted(fetchSubmissions)

    return {
      twineFiles,
      audios,
      error,
      rules,
      username,
      uploadFile,
      allowedFileType
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
