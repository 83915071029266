<!--comment-->
<template>
  <v-app>
    <v-main>
      <!--<Navbar />-->
      <v-container class="app-container" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
#app {
  background: #FFF5DA;
  font-family: 'League Spartan', sans-serif;
}

.app-container {
  padding-left: 20%;
  padding-right: 20%;
}

/* Add this to apply the font to your hero headers */
h1 {
  font-family: 'Holtwood One SC', serif;
  line-height: 104.7%;
  color: #472852;
}
</style>

