<template>
  <div class="hub-component">
    <br /><br />
    <br /><br /><br /><br />
    <h1 class="text-center">Submissions</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="'image-' + index"
          cols="12"
          sm="4"
        >
          <v-sheet class="ma-2 pa-4" style="width: 256px; height: 256px;">
            <v-img
              :src="image.url ? image.url : 'https://via.placeholder.com/128x128'"
              aspect-ratio="1"
              contain
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <hr />

  <div>
    <v-file-input
      :rules="rules"
      :accept="allowedFileType"
      placeholder="Pick a file"
      prepend-icon="mdi-upload"
      label="Click To Upload..."
      @change="uploadFile"
      multiple
    ></v-file-input>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Submissions',
  setup() {
    const images = ref([])
    const error = ref(null)
    const rules = ref([]) // Add rules if needed
    const allowedFileType = ref('.gif') // Specify allowed file type

    const fetchSubmissions = async () => {
      console.log('fetchSubmissions function was called')
      try {
        const { data: submissionsData, error: submissionsError } = await supabase
          .storage
          .from('week2wed_library_workshop') // Use your storage bucket name
          .list('', { limit: 1000 })

        if (submissionsError) {
          throw submissionsError
        }

        images.value = [] // Clear the array before adding new URLs
        for (const { name: file } of submissionsData) {
          const { data: url, error: urlError } = await supabase
            .storage
            .from('week2wed_library_workshop') // Use your storage bucket name
            .download(file)

          let fileUrl // Declare fileUrl outside the condition

          if (url instanceof Blob && url.size > 0) {
            fileUrl = URL.createObjectURL(url);
            images.value.push({ url: fileUrl, name: file }); // Add the URL to the images array
          }

          if (urlError) {
            throw urlError
          }

          console.log('Downloaded file URL:', url)
        }
      } catch (error) {
        console.error('Error retrieving submissions:', error)
        error.value = error.message || 'Error retrieving submissions'
      }
    }

    const uploadFile = async (evt) => {
      const files = evt.target.files;
      if (!files || files.length === 0) return;

      const user = await supabase.auth.getSession();
      if (!user || !user.data.session.user.id) {
        alert('Please log in before uploading.');
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExt = file.name.split('.').pop();
        const timestamp = new Date().getTime();

        // Fetch the user's profile data using their UUID
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('username')
          .eq('id', user.data.session.user.id)
          .single();

        if (profileError) {
          console.error('Error fetching user profile:', profileError);
          return;
        }

        const newFileName = `${profileData.username}_${timestamp}.${fileExt}`;

        // Upload the file with the new name
        const { error: uploadError } = await supabase.storage
          .from('week2wed_library_workshop') // Use your storage bucket name
          .upload(newFileName, file);

        if (uploadError) {
          console.error("Error uploading file:", uploadError);
          return;
        }
      }

      console.log('Files uploaded and renamed successfully!');
      location.reload();
    }

    onMounted(fetchSubmissions)

    return {
      images,
      error,
      rules,
      allowedFileType,
      uploadFile
    }
  }
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>