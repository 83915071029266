<template>
  <v-row no-gutters>
    <v-col
      v-for="(profile, index) in profiles"
      :key="index"
      cols="10"
      sm="1"
    >
      <router-link :to="`/profiles/${profile.username}`">
        <v-sheet class="ma-2 pa-8">
          <v-img :src="profile.avatar ? profile.avatar : 'https://via.placeholder.com/128x128'" aspect-ratio="1" contain></v-img>
          {{ profile.username }}
        </v-sheet>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase } from '../plugins/supabase'

export default {
  name: 'Discover',
  setup() {
    const profiles = ref([])

    onMounted(async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')

      if (error) {
        console.error('Error loading profiles:', error)
      } else {
        profiles.value = data
      }
    })

    return { profiles }
  }
}
</script>

<style scoped>
h1 {
  font-family: 'League Spartan', sans-serif;
}
</style>
